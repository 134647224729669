a {
  text-decoration: none !important;
  color: black !important;
}

.logo {
  height: 34px;
}

.navbar-nav a {
  padding: 0px 20px !important;
}

.nav-icon {
  color: white;
  font-size: 24px;
}

.carousel {
  margin: 0px 150px;
}

.carousel-caption {
  bottom: 12rem !important;
}

.carousel-caption h3 {
  font-size: 40px;
  color: white;
  text-shadow: 0 1px 2px #0003;
}

.carousel-caption p {
  font-size: 26px;
  color: white;
  text-shadow: 0 1px 2px #0003;
}

.nft .cover {
  aspect-ratio: 1/1;
}

.nft-image {
  width: 100%;
  object-position: center;
  max-height: 320px;
}

.nft-title {
  height: 47px;
}

.social-icon {
  border: 1px solid rgba(87, 87, 87, 0.2);
  padding: 5px;
  margin: 5px;
}